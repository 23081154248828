import React from "react"
import styled from "styled-components"
import FacebookIcon from "../images/social/facebook.svg"
import GitHubIcon from "../images/social/github.svg"
import TwitterIcon from "../images/social/twitter.svg"
import SpotifyIcon from "../images/social/spotify.svg"
import InstagramIcon from "../images/social/instagram.svg"

import "../styles/global.css"
import "../styles/normalize.css"

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 12px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: 16px;
  @media (min-width: 1026px) {
    justify-content: flex-end;
  }
`
const IconWrapperLeftEnd = styled.a`
  margin-right: 8px;
`

const IconWrapper = styled.a`
  margin-right: 8px;
  margin-left: 8px;
`

const IconWrapperRightEnd = styled.a`
  margin-left: 8px;
`

const IconInnerWrapper = styled.div`
  display: inline-block;
  min-width: 48px;
  @media (min-width: 1026px) {
    min-width: 46px;
  }
`

function Footer() {
  return (
    <Wrapper>
      <IconWrapperLeftEnd
        href="https://github.com/mnabeshimase"
        target="_blank"
        rel="noreferrer nofollow"
        title="link to GitHub"
      >
        <IconInnerWrapper>
          <GitHubIcon />
        </IconInnerWrapper>
      </IconWrapperLeftEnd>
      <IconWrapper
        href="https://open.spotify.com/user/1265756387?si=iRVw-DLMSxG0yfXpd39f4g"
        target="_blank"
        rel="noreferrer nofollow"
        title="link to Spotify"
      >
        <IconInnerWrapper>
          <SpotifyIcon />
        </IconInnerWrapper>
      </IconWrapper>
      <IconWrapper
        href="https://www.facebook.com/14eafa09bf75cf6d759ddda1e5af9044"
        target="_blank"
        rel="noreferrer nofollow"
        title="link to Facebook"
      >
        <IconInnerWrapper>
          <FacebookIcon />
        </IconInnerWrapper>
      </IconWrapper>
      <IconWrapper
        href="https://www.instagram.com/konokone/"
        target="_blank"
        rel="noreferrer nofollow"
        title="link to Instagram"
      >
        <IconInnerWrapper>
          <InstagramIcon />
        </IconInnerWrapper>
      </IconWrapper>
      <IconWrapperRightEnd
        href="https://twitter.com/mnabeshimase"
        target="_blank"
        rel="noreferrer nofollow"
        title="link to Twitter"
      >
        <IconInnerWrapper>
          <TwitterIcon />
        </IconInnerWrapper>
      </IconWrapperRightEnd>
    </Wrapper>
  )
}

export default Footer
