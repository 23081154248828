import React, { useState } from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import Footer from "../components/footer"

import "../styles/global.css"
import "../styles/normalize.css"

const Index = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: 1fr;
`

const Main = styled.div`
  grid-row: 1 / 12;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FooterWrapper = styled.div`
  display: flex;
  align-items: bottom;
  grid-row: 12/ 13;
`

const EmojiWrapper = styled.div`
  padding-right: 12px;
  padding-left: 12px;
  cursor: default;
`

const Emoji = styled.span`
  font-size: 96px;
  user-select: none;
`

const IndexPage = () => {
  const [emojiWrapperFocus, setEmojiWrapperFocus] = useState(false)
  const [emojiWrapperHover, setEmojiWrapperHover] = useState(false)
  return (
    <>
      <SEO />
      <Index>
        <Main>
          <EmojiWrapper
            tabIndex="0"
            onFocus={() => setEmojiWrapperFocus(true)}
            onBlur={() => setEmojiWrapperFocus(false)}
            onMouseEnter={() => setEmojiWrapperHover(true)}
            onMouseLeave={() => setEmojiWrapperHover(false)}
          >
            {emojiWrapperFocus || emojiWrapperHover ? (
              <Emoji role="img" aria-label="smiling face with 3 hearts">
                🥰
              </Emoji>
            ) : (
              <Emoji role="img" aria-label="sneezing">
                🤧
              </Emoji>
            )}
          </EmojiWrapper>
        </Main>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Index>
    </>
  )
}

export default IndexPage
